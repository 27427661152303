import { defineStore } from 'pinia';

export const useGlobalStore = defineStore('global', {
 state: () => ({
  site_name: '',
  site_description: '',
  site_favicon: '',
  donateDetails: {},
  hours: '',
  logo: {
   id: 0,
   url: '',
   alt: '',
  },
  socialLinks: {
   facebook: '',
   instagram: '',
   x: '',
   tiktok: '',
   linkedin: '',
   pinterest: '',
  },
  company_info: {
   brand_name: '',
   phone_number: '',
   contact_email: '',
   location: '',
  },
  cookies_message: {
   headline: 'This website uses cookies',
   message:
    'Our website uses cookies to improve your browsing experience. By using our site you agree to the use of cookies.',
  },
  generalInformation: {},
  announcements: [],
  specialHours: {},
  errorImages: {
   desktopImage: '',
   mobileImage: '',
   altTag: '',
  },
  ticketPricingMap: [],
  tourDetailPage: {},
  menu: {},
  searchOptions: {},
 }),
 actions: {
  init(data) {
   if (!data) return;
   if (data.generalInformation) this.generalInformation = data.generalInformation;
   else if (process.server) console.error('No general information found in global data');

   if (data.donateDetails) this.donateDetails = data.donateDetails;
   else if (process.server) console.error('No donate details found in global data');

   if (data.socialLinks) this.socialLinks = data.socialLinks;
   else if (process.server) console.error('No social links found in global data');

   if (data.announcements) this.announcements = data.announcements;
   else if (process.server) console.error('No announcements found in global data');

   if (data.specialHours) this.specialHours = data.specialHours;
   else if (process.server) console.error('No special hours found in global data');

   if (data.errorImages) this.errorImages = data.errorImages;
   else if (process.server) console.error('No error images found in global data');

   if (data.ticketPricingMap) this.ticketPricingMap = data.ticketPricingMap;
   else if (process.server) console.error('No ticket pricing map found in global data');

   if (data.tourDetailPage) this.tourDetailPage = data.tourDetailPage;
   else if (process.server) console.error('No tour detail page found in global data');

   if (data.menu) this.menu = data.menu;
   else if (process.server) console.error('No menu found in global data');

   if (data.searchOptions) this.searchOptions = data.searchOptions;
   else if (process.server) console.error('No search options found in global data');
  },
 },
});
